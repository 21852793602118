import React from "react"
import {
  SubAndHeadline,
  CenterContent,
  Address,
  LabelNumber,
  Email,
} from "../components/_index"
import { Button, Grid, Typography } from "@material-ui/core"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import SiteMetadata from "../components/SiteMetaData"
import Layout from "../components/Layout"
import { ADDRESS_COPY, SNACKBAR_SUCCESS_COPY } from "../utils/constants"
import { CopyToClipboard } from "react-copy-to-clipboard"
import Place from "@material-ui/icons/Place"
import SnackBarComponent from "../components/SnackBar"
import useOpenHours from "../hooks/useOpenHours"
import WorkingHours from "../components/WorkingHours"

const Contact = ({ data }) => {
  const {
    title,
    subtitle,
    phoneNo,
    faxNo,
    email,
    address,
    businessTime: { businessTimeTitle },
  } = {
    ...data.content,
  }
  const addressAsString = `${address.street} ${address.streetNo}, ${address.postalCode} ${address.city}`
  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false)
  const { businessHours } = useOpenHours()

  return (
    <Layout>
      <SiteMetadata title={title} description={subtitle} />
      <CenterContent>
        <Grid container direction="column" spacing={3} align={"center"}>
          <SubAndHeadline title={title} subtitle={subtitle} />
          <Address address={address} />
          <Grid item>
            <CopyToClipboard
              text={addressAsString}
              onCopy={() => setIsSnackBarOpen(true)}
            >
              <Button variant={"outlined"} startIcon={<Place />}>
                <Typography variant={"body2"}>{ADDRESS_COPY}</Typography>
              </Button>
            </CopyToClipboard>
          </Grid>
          <LabelNumber label={"Tel.: "} number={phoneNo} pb={0} callable />
          <LabelNumber label={"Fax: "} number={faxNo} pt={0} />
          <Email eMail={email} />
          <WorkingHours
            title={businessTimeTitle}
            businessHours={{ ...businessHours }}
          />
          <SnackBarComponent
            text={SNACKBAR_SUCCESS_COPY}
            isOpen={isSnackBarOpen}
            handleOpen={setIsSnackBarOpen}
          />
        </Grid>
      </CenterContent>
    </Layout>
  )
}

Contact.propTypes = {
  data: PropTypes.object,
}

export const query = graphql`
  query {
    content: contentfulContact {
      title
      subtitle
      phoneNo: telephone
      faxNo: fax
      email: eMail
      address {
        title
        street
        streetNo
        postalCode
        city
      }
      businessTime: businessHours {
        businessTimeTitle: title
      }
    }
  }
`

export default Contact
