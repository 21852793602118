import { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { WEEK_DAYS_EN } from "../utils/constants"
import moment from "moment-business-time"

export default () => {
  const { contentfulWorkingHours: workingHours } = useStaticQuery(
    graphql`
      query CONTENTFUL_WORKING_HOURS {
        contentfulWorkingHours {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
        }
      }
    `
  )

  useEffect(() => {
    moment.updateLocale("en", {
      weekdays: WEEK_DAYS_EN.split("_"),
      workinghours: {
        0: null, // Sun : CLOSED
        1: workingHours.monday, // Mon
        2: workingHours.tuesday, // Tue
        3: workingHours.wednesday, // Wed
        4: workingHours.thursday, // Thu
        5: workingHours.friday, // Fri
        6: workingHours.saturday, // Sat
      },
    })
  })

  return {
    businessHours: { ...workingHours },
    isWorking: moment().isWorkingTime(),
  }
}
